
.file-list-container{
     width: 100%;
    height: 50vh;
}

.checkbox-center{
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.file-card-container{
    height: 95%;
    background-color: var(--card-bg-color) !important;
    padding: 24px;
    border-radius: 8px;
}

.title-header{
    color: var(--font-color);
    font-size: 16px;

}

.icon{
    color: var(--font-color);
    font-size: 20px;
}

.result-count{
    font-size: 12px;
    border-radius: 32px;
    color: var(--font-color);
    border: 1px solid var(--font-color);
    padding: 2px 8px;
}

/* const cardContainerStyle = {
    height: "95%",
    backgroundColor: "#FAFCFE",
    padding: 24,
    borderRadius: 8,
}; */


@media only screen and (min-width: 64em){
    .file-list-container{
        height: 80vh;
    }
}