.card-viewer{
    height: 80vh;
    border-radius: 0 0 8px 8px;
    background-color: #FAFCFE;
}

.card-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    gap: 20px;
    margin-bottom: 24px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    background-color: #FAFCFE;
    border-radius: 8px 8px 0 0;
    flex-wrap: wrap;
}

.navigation{
    display: flex;
    gap: 30px;
}

.view-excel{
    font-size: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: inherit;
}
 
.view-excel .sub-text{
    font-size: 18px;
    margin: 16px 0;
}

.container-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.card-viewer .MuiCardContent-root:last-child{    
    height: inherit;
}

@media only screen and (min-width: 40em){
    
}

@media only screen and (min-width: 48em){
}

@media only screen and (min-width: 52em){
    .card-header{
        flex-direction: row;
        justify-content: space-between;
    } 
}

@media only screen and (min-width: 64em){
    .card-viewer{
        height: 90%;
    }

    .viewer-container{
        display: flex;
        flex-direction: column-reverse;
    }

}

@media only screen and (min-width: 80em){
    .card-viewer{
        height: 91%;
    }

    .viewer-container{
        display: flex;
        flex-direction: column-reverse;
    }

}

@media only screen and (min-width: 80em){
    .card-viewer{
        height: 87.5%;
    }


}