.fields-container-agua{
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    grid-template-rows: auto;
    gap: 12px 24px;
}
.MuiInputBase-root {
    border-radius: 14px !important;
    padding-right: 14px !important;

}
.buttons-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 24px 14px;
}

.MuiTypography-root{
    width: fit-content !important;
}


@media only screen and (min-width: 40em) {
}

@media only screen and (min-width: 48em) {
    .fields-container-agua{
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media only screen and (min-width: 52em) {
    .fields-container-agua{
        grid-template-columns: repeat(3, 1fr); 
    }
}
