.fields-container {
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 12px 24px;
}

.MuiInputBase-root {
    border-radius: 14px !important;
    padding-right: 14px !important;

}

.subgrid {
    display: flex;
    gap: 12px 24px;
    flex-direction: column;
}

.buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 24px 14px;
}


@media only screen and (min-width: 40em) {}

@media only screen and (min-width: 48em) {}

@media only screen and (min-width: 52em) {
    .fields-container {
        margin: 24px 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 12px 24px;
    }

    .subgrid {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-column-end: 3;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 12px 24px;
    }

}