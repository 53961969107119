.header{
    background-color: transparent !important;
}

.logos-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 45px;
}

.sabesp-logos{
    display: flex;  
    flex-wrap: wrap;  
    justify-content: center;
    align-items: center;
}


@media only screen and (min-width: 40em) {
    .logos-container{
        justify-content: space-between;
    }
}

