.formElement {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.projectSelect {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}