.fields-container-adm{
    margin: 24px 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    gap: 12px 24px;
}

.user-info{
    cursor: auto !important;
    opacity: 1 !important;
}

.user-info:hover{
    background: #ffffff !important;
}
.user-name{
    padding-bottom: 0 !important;
}

.user-email{
    padding-top: 0 !important;
    opacity: 0.75 !important;
    font-size: 14px !important;
}

.class-table{
    max-height: 60vh;
}

.class-table > .MuiDataGrid-root{
    max-height: inherit;
}


.print-page{
    max-width: 105vh;
}

.adm-header{
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

}
 .data-range-logs > .rs-input-group {
    border-radius: 10px !important
} 


.auto-complete-logs .MuiInputBase-root{
    border-radius: 10px !important
}

.buttons-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 24px 14px;
}

.MuiTypography-root{
    width: fit-content !important;
}


@media only screen and (min-width: 48em) {
    .fields-container-adm{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (min-width: 52em) {
    .fields-container-adm{
        grid-template-columns: repeat(5, 1fr);
    }
}
